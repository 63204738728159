define("ember-component-css/pod-names", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "debug-mode/complete-stones": "__51674",
    "debug-mode/intro-video": "__ce03a",
    "debug-mode/placement-test": "__24b79",
    "debug-mode/toggle-debug": "__790d6",
    "debug-mode/yeti-shop": "__bb54f",
    "error-screen": "__34a83",
    "loading-screen": "__e1b04"
  };
});